import React, { useState } from 'react';
import '../index.css';
import malitpicbig from '../malitbigpic.png'; 
import cashitinpic from '../cashitinimg.png';

const RoundedSquare = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [shadowPosition, setShadowPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY, target } = e;
    const rect = target.getBoundingClientRect();
    
    const x = clientX - rect.left - 25;
    const y = clientY - rect.top - 25;

    setShadowPosition({ x, y });
  };

  const handleMouseEnter = (e) => {
    if (!e.target.closest('.no-shadow')) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="rounded-square intro-text2 "
      style={{ marginTop: '5rem'}}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="shadow"
        style={{
          transform: `translate(${shadowPosition.x}px, ${shadowPosition.y}px)`,
          opacity: isHovered ? 1 : 0,
        }}
      ></div>

      <div className='projects pointer-events-none' style={{ minHeight: '55rem' }}>
        <div className='project-title text-white title-font text-7xl text-center mt-10'>
          <h1>PROJECTS</h1>
        </div>

        <div className="list flex justify-center md:justify-between flex-wrap projectgap mt-20 w-full px-3">
          <div className="project flex-1 flex justify-center">
            <div className="project-square w-80 h-80 lg:w-72 lg:h-72 md:w-60 md:h-64 sm:w-56 sm:h-56 xs:w-52 xs:h-48 border-2 border-white rounded-lg flex justify-center items-center"
              style={{
                backgroundImage: `url(${malitpicbig})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                marginBottom: '7rem'
              }}
            >
              {/* Text describing the project */}
              <div className="text-white text-center mt-3 mt25 custom-font text-2xl max-md:text-xl max-md:mt-96 xs:mb-12">
                <p className=''>
                  A PHP, Bootstrap and MySQL IT services&nbsp;
                  <a href="https://malfabit.com/" target="_blank" rel="noopener noreferrer" className="underline clickable-link no-shadow"
                     onMouseEnter={() => setIsHovered(false)}
                     onMouseLeave={() => setIsHovered(true)}
                  >
                    website
                  </a>
                  &nbsp;that I made.
                </p>
              </div>
            </div>
          </div>

          <div className="project flex-1 flex justify-center">
            <div className="project-square w-80 h-80 lg:w-72 lg:h-72 md:w-60 md:h-64 sm:w-56 sm:h-56 xs:w-52 xs:h-48 border-2 border-white rounded-lg flex justify-center items-center"
             



             style={{
              backgroundImage: `url(${cashitinpic})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              
            }}
          >


              {/* Text describing the project */}
              <div className="text-white text-center mt-3 mt25 custom-font text-2xl max-md:text-xl max-md:mt-96 xs:mb-12">
                <p className=''>
                  A ReactJS and Tailwind&nbsp;
                  <a href="https://www.cash-it-in-goole.com/" target="_blank" rel="noopener noreferrer" className="underline clickable-link no-shadow"
                     onMouseEnter={() => setIsHovered(false)}
                     onMouseLeave={() => setIsHovered(true)}
                  >
                    site
                  </a>
                  &nbsp;i was asked to make for a 2nd hand retail shop in my town.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundedSquare;



